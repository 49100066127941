import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useLocation } from 'react-router-dom'
import { Modal, ModalBody } from 'reactstrap'
import Slider from 'react-slick'
import Header from '../../components/Header'
import Footer from '../../components/footer'
import Loading from '../../components/Loader'
import Sidebar from '../../components/Sidebar'
import ScrollTop from '../../config/scroll'
import redballimg from '../../assets/images/red-ball-img.png'
import blueballimg from '../../assets/images/blue-ball-img.png'
import { getQuickPickTicket } from '../../query/nori/nori.query'
import { getCurrentPoolData } from '../../query/home/home.query'
import '../../assets/sass/views/Nori.scss'
import '../../assets/sass/views/common.scss'
import { useTranslation } from 'react-i18next'
import BuyModal from '../../components/buyModal'

import readyimg from '../../assets/images/ready-logo.png'
import infoicon from '../../assets/images/info-icon.png'
import loaderimg from '../../assets/images/loading.gif'
import completedicon from '../../assets/images/completed-icon.svg'
import closeicon from '../../assets/images/close-icon.svg'
import previcon from '../../assets/images/arrow-left.png'
import nexticon from '../../assets/images/arrow-right.png'

import ModalLimite from '../../components/Modal'
import TicketModal from '../Home/TicketModal'

const QuickPick = (props) => {
  const { t } = useTranslation('global')
  const { state } = useLocation()
  const navigate = useNavigate()
  const ncbTickets = state?.ncbTickets
  const stepsTickets = state?.stepsTickets ? state.stepsTickets : '[]'
  const [homeData, setHomeData] = useState({})
  const [isFetch, setIsFetch] = useState(false)
  const [showBuy, setShowBuy] = useState(false)
  const [tickets, setTickets] = useState(state?.ticket
    ? state.ticket
    : [
        { id: 1, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 2, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 3, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 4, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 5, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 6, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 7, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 8, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 9, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 10, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 11, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 12, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 13, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 14, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false }
      ])
  const [loading, setLoading] = useState({ status: true })
  const [noOfTicket, setNoOfTickets] = useState('1')
  const [qpGenerationComp, setQpGenerationComp] = useState(false)
  const [error, setError] = useState(false)
  const [activeModal, setActiveModal] = useState(0)
  const [overTicket, setOverTicket] = useState(false)
  const [quickOption, setQuickOption] = useState('1')
  const [value, setValue] = useState(50)
  const onOptionChange = e => {
    setQuickOption(e.target.value)
  }
  const winningnumbersslider = () => {
    return ({
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      swipeToSlide: true,
      focusOnSelect: true,
      centerPadding: '10px',
      vertical: true,
      infinite: true,
      swipe: false,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            vertical: false
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            vertical: false
          }
        }
      ]
    })
  }
  const [isVertical, setIsVertical] = useState(true)
  const [slides, setSlides] = useState(4)
  useEffect(() => {
    const handleResize = () => {
      // Si la largeur de l'écran est inférieure à 768px, on active le défilement vertical
      if (window.innerWidth < 991) {
        setIsVertical(false)
      } else {
        setIsVertical(true)
      }
      if (window.innerWidth < 768) {
        setSlides(2)
      } else {
        setSlides(4)
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const [modal, setModal] = useState(false)
  const togglemodal = () => {
    return setModal(!modal)
  }

  useQuery(['current-pool', isFetch], getCurrentPoolData, {
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      if (!state) setHomeData(data)
      setIsFetch(false)
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.quickPick.error') } })
    }
  })
  const goPrev = () => {
    winningnumbersslider.current.slickPrev()
  }
  const goNext = () => {
    winningnumbersslider.current.slickNext()
  }
  const { refetch, isLoading } = useQuery('quick-pick', () => getQuickPickTicket(Number(noOfTicket), Number(quickOption), Number(value)), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: (data) => {
      const finalTicketState = [...tickets]
      const indexFind = finalTicketState?.findIndex((t) => {
        return t.selectedNo.length === 0
      })
      if (indexFind > -1) {
        for (let i = indexFind; i < indexFind + data.length; i++) {
          for (let j = 0; j < data.length; j++) {
            finalTicketState[indexFind + j] = { id: j + indexFind + 1, selectedNo: data[j].n, totalSelectedNo: 6, autoGenerated: true, confirmClick: true }
          }
        }
      } else {
        for (let j = 0; j < data.length; j++) {
          finalTicketState.push({ id: tickets.length + j, selectedNo: data[j].n, totalSelectedNo: 6, autoGenerated: true, confirmClick: true })
        }
      }
      if (!finalTicketState[finalTicketState?.length]?.selectedNo?.length) finalTicketState.push({ id: finalTicketState.length + 1, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false })
      setTickets(finalTicketState)
      setQpGenerationComp(true)
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.quickPick.error') } })
    }
  })
  const handleClick = (no) => {
    if (no === 1) {
      setActiveModal(no)
      togglemodal()
    } else if (no === 2) {
      setQpGenerationComp(false)
      if (noOfTicket === '' || Number(noOfTicket) === 0 || noOfTicket === '-') setError(true)
      else setError(false)
      if (noOfTicket !== '' && Number(noOfTicket) !== 0 && noOfTicket !== '-') {
        setActiveModal(no)
        const indexFind = tickets?.findIndex((t) => {
          return t.selectedNo.length === 0
        })
        if (Number(noOfTicket) + indexFind > 2000) togglemodal()
        else refetch()
      }
    } else if (no === 3) {
      refetch()
      togglemodal()
    }
  }

  const handleChange = (e) => {
    const regex = /^[0-9]+$/
    if (e.target.value === '') {
      setError(false)
    } else {
      if (!regex.test(e.target.value)) {
        // eslint-disable-next-line no-useless-escape
        e.target.value = e.target.value.replace(/([a-zA-Z =&\/\\#, +()$~%.\-'":*?<>{}])/g, '')
        setError(true)
      } else {
        setError(false)
      }
      if (e.target.value > 2000) {
        setOverTicket(true)
        e.target.value = e.target.value.replace(/([0-9])/g, '')
        setShow(true)
      } else {
        setOverTicket(false)
      }
    }
    setNoOfTickets(e.target.value)
  }

  const handleNext = () => {
    navigate('/', { state: { ticketState: tickets, noOfComb: noOfTicket, currentPoolData: state?.currentPoolData || homeData.currentPoolData, ncbTickets: ncbTickets, stepsTickets: stepsTickets } })
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading({ status: false })
    }, 500)
    if (state === null) { setIsFetch(true) }
  }, [])

  const [show, setShow] = useState(false)

  const [selectedTicket, setSelectedTicket] = useState(1)
  const [showTicketModal, setShowTicketModal] = useState(false)
  const showTicketModalToggle = (x) => {
    setSelectedTicket(x)
    return setShowTicketModal(!showTicketModal)
  }

  return (
    <>
      {/** <Modal2 title="Important Note!" content1="Buying tickets is not possible in this alpha test version." validate="OK" onClose={() => setShowBuy(false)} show={showBuy} /> */}
      {showBuy
        ? tickets[0].confirmClick
          ? <BuyModal
            showBuy={showBuy}
            setShowBuy={setShowBuy}

            nbrTicket={tickets.filter((t) => t.totalSelectedNo === 6).length}
            competition={tickets.some((t) => t.noriCompetition)}
            ticket={tickets}

            nps={JSON.parse(stepsTickets)}
            ncb={ncbTickets}

          />
          : null
        : null
      }
      <ModalLimite title={t('global.attention')} content1={t('views.quickPick.limit')} validate={t('global.ok')} onClose={() => setShow(false)} show={show} />
      <TicketModal modal={showTicketModal} toggle={showTicketModalToggle} selectedTicket={tickets} setSelectedTicket={setTickets} ticketNo={selectedTicket} setTicketNo={setSelectedTicket} />
      <ScrollTop />
      {loading.status ? <Loading addClass={'active'} /> : ''}
      <Header activeTab={1} tickets={tickets} currentPoolData={state?.currentPoolData || homeData.currentPoolData} ncbTickets={ncbTickets} stepsTickets={stepsTickets} />
      <div className="stepper-sec quickpick-sec">
        <Sidebar sideActiveTab={1} tickets={tickets} currentPoolData={state?.currentPoolData || homeData.currentPoolData} ncbTickets={ncbTickets} stepsTickets={stepsTickets} />
        <div className="box">
          <img src={blueballimg} className='blueballimg' />
          <div className="step-component step-header">
            <div className='steps-left-sec left-header-sec '>
              <h1>{t('views.quickPick.title')}</h1>
            </div>
          </div>
          <div className="step-component">
            <div className='steps-left-sec'>
              <img src={redballimg} className='redballimg' />
              <div className='common-step-box'>
                <div className='text-center'>
                  <img src={readyimg} className='readyimg' />
                  <h2>
                    {t('views.quickPick.instruction')}
                    <div className='tooltip-heading '>
                      <img src={infoicon} className="infoicon" />
                      <span className="tooltiptext">{t('views.quickPick.instructionInfo')}</span>
                    </div>
                  </h2>
                </div>
                <div className='options'>
                  <div>
                    {t('views.quickPick.opt1')}
                    <input
                      type="radio"
                      name="quickOption"
                      value="1"
                      id="1"
                      checked={quickOption === '1'}
                      onChange={onOptionChange}
                    />
                    <span></span>
                  </div>
                  <div>
                    {t('views.quickPick.opt2')}
                    <input
                      type="radio"
                      name="quickOption"
                      value="2"
                      id="2"
                      checked={quickOption === '2'}
                      onChange={onOptionChange}
                    />
                    <span></span>
                  </div>
                  <div>
                    {t('views.quickPick.opt3')}
                    <input
                      type="radio"
                      name="quickOption"
                      value="3"
                      id="3"
                      checked={quickOption === '3'}
                      onChange={onOptionChange}
                    />
                    <span></span>
                  </div>
                  <div>
                    {t('views.quickPick.opt4')}
                    <input
                      type="radio"
                      name="quickOption"
                      value="4"
                      id="4"
                      checked={quickOption === '4'}
                      onChange={onOptionChange}
                    />
                    <span></span>
                  </div>
                </div>
                {
                  quickOption !== '1'
                    ? <div className="filterRange" >
                      <input type="range" min="10" max="100" step="10" value={value} onChange={(e) => setValue(e.target.value)} />
                      <ul>
                        {Array.from(Array(10), (op, i) =>
                          <li key={(i + 1) * 10} onClick={() => { setValue((i + 1) * 10) }}>{(i + 1) * 10}</li>
                        )}
                      </ul>
                      <div className='nori-form-grp nori-full-form' style={{ margin: '0px 0px 10px', padding: '0', 'background-color': 'transparent' }}>
                        <div className='tooltip-heading '>
                          <img src={infoicon} className="infoicon" />
                          <span className="tooltiptext">{t('views.quickPick.degreeInfo')}</span>
                        </div>
                        {value} {t('views.quickPick.lastDraws')}
                      </div>
                    </div>
                    : null
                }

                <div className='nori-form-grp nori-full-form'>
                  <div>
                    <div className='tooltip-heading '><img src={infoicon} className="infoicon" />
                      <span className="tooltiptext">{t('views.nori.step1.instruction')}</span>
                    </div>{t('views.quickPick.numberOfTickets')}
                  </div>
                  <input type="text" className='quickpick-input' defaultValue={noOfTicket} onInput={(e) => handleChange(e)} />
                </div>
                {error &&
                  (noOfTicket <= '0' || noOfTicket !== '')
                  ? <div><p className='error-msg show'>{t('views.quickPick.validNumber')}.</p></div>
                  : ''}
                {overTicket
                  ? <div><p className='error-msg show'>{t('views.quickPick.limit')}</p></div>
                  : ''}
                {isLoading
                  ? <div className="activeProgressbar"> <img src={loaderimg} /></div>
                  : ''
                }<div className='generate-tickets-btns justify-content-end'>
                  <a className='common-blue-btn small-btn' onClick={() => handleClick(2)}><span>{t('views.quickPick.genTicket')}</span></a>
                </div>
                {qpGenerationComp && <div>
                  <div className='generation-completed'><span>{t('views.quickPick.genComplete')}<img src={completedicon} /></span></div>
                </div>
                }
              </div>
              <div className='btn-component'>
                <div>
                  <input type="button" value={t('views.quickPick.back')} onClick={handleNext} />
                </div>
                {qpGenerationComp
                  ? <div>
                    <input className='buyLuncher' type="button" value={t('views.quickPick.buy')} onClick={() => setShowBuy(true)} />
                  </div>
                  : ''
                }
              </div>
            </div>
            <div className='step-right-slider-sec'>
              {qpGenerationComp
                ? <div className='generationCompleteLight'></div>
                : ''
              }
              <button onClick={goPrev} className='custom-left-btn'>
                <img src={previcon} />
              </button>
              <button onClick={goNext} className='custom-right-btn'>
                <img src={nexticon} />
              </button>
              <Slider slidesToShow={slides} {...winningnumbersslider} vertical={isVertical} ref={winningnumbersslider} className="right-step-slider">
                {tickets.length && tickets.map(ticket =>
                  ticket.totalSelectedNo === 6
                    ? <div key={ticket.id} onClick={() => { showTicketModalToggle(ticket.id); sessionStorage.setItem('tmpList', JSON.stringify(ticket)) }}>
                      <ul className='hexagone-list orange'>
                        {Array.from(Array(42), (op, i) =>
                          ticket?.selectedNo.includes(i + 1) === true
                            ? <li key={i + 1} className='active'>{i + 1}</li>
                            : <li key={i + 1}></li>
                        )}
                      </ul>
                      <span className={ticket.id > 999 ? 'ticket-number three' : 'ticket-number'}>{ticket.id}</span>
                    </div>
                    : <div key={ticket.id} onClick={() => { (ticket.id === 1 || tickets[(ticket.id - 2)]?.totalSelectedNo === 6) ? showTicketModalToggle(ticket.id) : <div></div> }}>

                      <ul className='hexagone-list'>
                        {Array.from(Array(42), (op, i) =>
                          ticket?.selectedNo?.includes(i + 1) === true
                            ? <li key={i + 1} className='active'>{i + 1}</li>
                            : <li key={i + 1}></li>
                        )}
                      </ul>
                      <span className={ticket.id > 999 ? 'ticket-number three' : 'ticket-number'}>{ticket.id}</span>
                    </div>
                )}
              </Slider>
            </div>

          </div>
        </div>
      </div>
      <Footer isVisibleBall={false} scoreDate={state?.currentPoolData?.lastWiNs || homeData.lastWiNs} currentPool={state?.currentPoolData?.lastDraws || homeData.lastDraws} graphData={state?.currentPoolData?.lastHundredDraws || homeData.lastHundredDraws} />
      {activeModal === 1
        ? <Modal toggle={togglemodal} className='settings-popup' isOpen={modal}>
          <ModalBody>
            <div className='common-modal-sec'>
              <button onClick={togglemodal} className='common-close-btn'><img src={closeicon} /></button>
              <h3>{t('views.quickPick.confirmation')}</h3>
              <p>{t('views.quickPick.beSure')}</p>
              <div className='settings-btn-grp'>
                <a onClick={togglemodal} className='small-btn common-white-btn'>{t('views.quickPick.cancel')}</a>
                <a onClick={handleNext} className='small-btn common-blue-btn'>{t('views.quickPick.agree')}</a>
              </div>
            </div>
          </ModalBody>
        </Modal>
        : <Modal toggle={togglemodal} className='settings-popup' isOpen={modal}>
          <ModalBody>
            <div className='common-modal-sec'>
              <button onClick={togglemodal} className='common-close-btn'><img src={closeicon} /></button>
              <h3>{t('global.attention')}</h3>
              <p>{t('views.quickPick.additional')}</p>
              <div className='settings-btn-grp'>
                <a onClick={togglemodal} className='small-btn common-white-btn'>{t('global.ok')}</a>
              </div>
            </div>
          </ModalBody>
        </Modal>
      }
    </>
  )
}
QuickPick.propTypes = {
}

export default QuickPick
