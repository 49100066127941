import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { generateRandomNo } from '../../../utils/utils'
import infoicon from '../../../assets/images/info-icon.png'
import numberbg from '../../../assets/images/numbers-bg.png'
import { useTranslation } from 'react-i18next'

const ThirdComponent = (props) => {
  const { t } = useTranslation('global')
  const selectedExcludeNum = (number) => {
    const result = [...props.steps]
    if (result[6].selPriGrpNum.includes(number) && !result[4].selectedExcludeNum.includes(number)) {
      result[4].selectedExcludeNum.push(Number(number))
      result[6].selPriGrpNum.splice(result[6].selPriGrpNum.indexOf(number), 1)
    } else {
      const finalArr = result.find((x) => x.id === 3 && !x.selectedExcludeNum.includes(number))
      if (finalArr === undefined) {
        result[4].selectedExcludeNum.splice(result[4].selectedExcludeNum.indexOf(number), 1)
      } else {
        result[4].selectedExcludeNum.push(number)
      }
    }
    props.setSteps(result)
    props.setPrevActiveStep(false)
    setSelectedValue(result[4].selectedExcludeNum.length)
  }
  const [selectedValue, setSelectedValue] = useState([props.steps[4].selectedExcludeNum.length])
  const randomNo = (e) => {
    const result = [...props.steps]
    if (result[4].selectedExcludeNum.length < Number(e)) {
      const count = Number(e) - result[4].selectedExcludeNum.length
      for (let i = 0; i < count; i++) {
        const res = generateRandomNo()
        if (result[6].selPriGrpNum.includes(res) && !result[4].selectedExcludeNum.includes(res)) {
          result[4].selectedExcludeNum.push(Number(res))
          result[6].selPriGrpNum.splice(result[6].selPriGrpNum.indexOf(res), 1)
        }
        if (!result[4].selectedExcludeNum.includes(res) && !result[2].selectedNum.includes(res)) {
          result[4].selectedExcludeNum.push(Number(res))
        } else {
          i--
        }
      }
    } else {
      const count = result[4].selectedExcludeNum.length - Number(e)
      for (let j = 0; j < count; j++) {
        result[4].selectedExcludeNum.pop()
      }
    }
    props.setPrevActiveStep(false)
    props.setSteps(result)
  }
  return <div>
    <div className='common-step-box'>
      <div className='text-center'>
        <h4 className='common-title '>{t('views.nori.step3.title')}<div className='tooltip-heading d-inline-block'>
          <img src={infoicon} className="infoicon" />
          <span className="tooltiptext">{t('views.nori.step3.instruction')}</span>
        </div>
        </h4>
        <p>{t('views.nori.step3.select')}</p>
        <div className='nori-mandetory-ticket-sec'>
          <div className='nori-ticket-sec min-tab-unset'>
            <img src={numberbg} />
            <ul className='mandetory-number-list'>
              {Array.from(Array(42), (op, i) =>
                props.steps[2].isSelected && props.steps[2].selectedNum.includes(i + 1)
                  ? <><li key={i + 1} className='active'>{i + 1}</li><br /></>
                  : props.steps[6].isSelected && props.steps[6].selPriGrpNum.includes(i + 1) === true
                    ? <><li key={i + 1} className='yellow' onClick={() => selectedExcludeNum(i + 1)} >{i + 1}</li><br /></>
                    : props.steps[4].isSelected && props.steps[4].selectedExcludeNum.length > 35 && !props.steps[4].selectedExcludeNum.includes(i + 1) ? <><li key={i + 1} className='disabled'>{i + 1}</li><br /></> : <><li key={i + 1} onClick={() => selectedExcludeNum(i + 1)} className={props.steps[4].isSelected && props.steps[4].selectedExcludeNum.includes(i + 1) === true ? 'red' : ''}><a>{i + 1}</a></li><br /></>
              )}
            </ul>
          </div>
          <div className='mandetory-number-footer'>
            {t('views.nori.step3.random')}<div className='drop-sec'>
              <select value={selectedValue} onChange={(e) => { randomNo(e.target.value); setSelectedValue(e.target.value) }}>
                {Array.from(Array(37), (op, i) =>
                  <option key={i} value={i}>{i}</option>
                )}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

ThirdComponent.propTypes = {
  steps: PropTypes.array,
  setSteps: PropTypes.func,
  setPrevActiveStep: PropTypes.func
}

export default ThirdComponent
