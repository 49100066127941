import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '../../../views/test/Box'
import { Dustbin } from '../../../views/test/Dustbin'
import infoicon from '../../../assets/images/info-icon.png'
import numberbg from '../../../assets/images/numbers-bg.png'
import { useTranslation } from 'react-i18next'

const SeventhComponent = (props) => {
  const { t } = useTranslation('global')
  const [min, setMin] = useState({ val: 6 }) // Math.max(props?.steps[2]?.selectedNum?.length, 6 - 0)
  const [max, setMax] = useState({ val: 6 }) // Math.min(6, 42 - 0)
  const [lastValue, setLastValue] = useState(props?.steps[5].quota === -1 ? 0 : props?.steps[5].quota)
  const [optionList, setOptionList] = useState([])
  const selectApplyPartiNum = (e, number) => {
    const result = [...props.steps]
    let lastData = lastValue
    let minValue = min.val
    let maxValue = max.val
    // eslint-disable-next-line eqeqeq
    if (typeof (e) === 'string') {
      setLastValue(Number(e))
      result[5].quota = Number(e)
    }
    if (lastData > max.val) lastData = max.val
    else if (lastData < min.val) lastData = min.val
    if (number) {
      const finalArr = result.find((x) => x.id === 7 && !x.selAppPartiNum.includes(number))
      if (finalArr === undefined) {
        result[5].selAppPartiNum.splice(result[5].selAppPartiNum.indexOf(number), 1)
      } else {
        if (number) result[5].selAppPartiNum.push(number)
      }
    }
    minValue = Math.max(result[2]?.selectedNum?.length, 6 - result[5].selAppPartiNum.length)
    maxValue = Math.min(6, 42 - result[5].selAppPartiNum.length - result[4].selectedExcludeNum.length)
    setMin({ ...min, val: minValue })
    setMax({ ...max, val: maxValue })
    handleOptionList(minValue, maxValue)
    props.setPrevActiveStep(false)
    props.setSteps(result)
  }

  useEffect(() => {
    const result = [...props.steps]
    let minValue = min.val
    let maxValue = max.val
    minValue = Math.max(result[2]?.selectedNum?.length, 6 - result[5].selAppPartiNum.length)
    maxValue = Math.min(6, 42 - result[5].selAppPartiNum.length - result[4].selectedExcludeNum.length)
    setMin({ ...min, val: minValue })
    setMax({ ...max, val: maxValue })
    handleOptionList(minValue, maxValue)
  }, [])

  const handleOptionList = (minimum, maximum) => {
    const finalOptions = []
    for (let i = minimum; i <= maximum; i++) {
      finalOptions.push(<option key={i} value={i}>{i}</option>)
    }
    setOptionList(finalOptions)
  }

  return <div>
    <div className='common-step-box'>
      <div className='text-center'>
        <h4 className='common-title'>{t('views.nori.step7.title')}<div className='tooltip-heading d-inline-block'>
          <img src={infoicon} className="infoicon" />
          <span className='tooltiptext'>{t('views.nori.step7.instruction')}</span>
        </div>
        </h4>
        <p>{t('views.nori.step7.infos')}</p>
        <div className='nori-mandetory-ticket-sec'>
          <div className='nori-ticket-sec'>
            <div className='drag-sec'>
              <img src={numberbg} />
              <div className='dustbin-sec' id='dust3'><Dustbin name={1} dustbin={1}></Dustbin></div>
              <div className='mandetory-number-list drag-item-list'>
                <section>
                  {Array.from(Array(42), (op, i) =>
                    props.steps[2].isSelected && props.steps[2].selectedNum.includes(i + 1)
                      ? <><Box id={i + 1} container={1} name={i + 1} key={i + 1} className='active'>{i + 1}</Box><br /></>
                      : (props.steps[4].isSelected && props.steps[4].selectedExcludeNum.includes(i + 1) === true) || (props.steps[5].isSelected && props.steps[5].selAppPartiNum.includes(i + 1) === true)
                          ? <><Box id={i + 1} container={1} name={i + 1} key={i + 1} className='disabled'>{i + 1}</Box><br /></>
                          : props.steps[6].isSelected && props.steps[6].selPriGrpNum.includes(i + 1) === true
                            ? <><Box id={i + 1} container={1} name={i + 1} key={i + 1} clickFn={(e) => selectApplyPartiNum(e, i + 1)} className='yellow'><a>{i + 1}</a></Box><br /></>
                            : <><Box id={i + 1} container={1} name={i + 1} key={i + 1} clickFn={(e) => selectApplyPartiNum(e, i + 1)}><a>{i + 1}</a></Box><br /></>
                  )}
                </section>
              </div>
            </div>
          </div>
          <div className='nori-ticket-sec'>
            <div className='drag-sec'>
              <img src={numberbg} />
              <div className='dustbin-sec' id='dust4' ><Dustbin name={2} dustbin={2}></Dustbin></div>
              <div className='mandetory-number-list drag-item-list'>
                <section>
                  {Array.from(Array(42), (op, i) =>
                    props.steps[2].isSelected && props.steps[2].selectedNum.includes(i + 1)
                      ? <><Box id={i + 1} container={2} name={i + 1} key={i + 1} className='disabled'>{i + 1}</Box><br /></>
                      : (props.steps[4].isSelected && props.steps[4].selectedExcludeNum.includes(i + 1) === true)
                          ? <><Box id={i + 1} container={2} name={i + 1} key={i + 1} className='disabled'>{i + 1}</Box><br /></>
                          : props.steps[5].isSelected && props.steps[5].selAppPartiNum.includes(i + 1)
                            ? <><Box id={i + 1} name={i + 1} container={2} key={i + 1} clickFn={(e) => selectApplyPartiNum(e, i + 1)}><a>{i + 1}</a></Box><br /></>
                            : <><Box id={i + 1} name={i + 1} container={2} key={i + 1} className='disabled'>{i + 1}</Box><br /></>
                  )}
                </section>
              </div>
            </div>
          </div>
          <div className='mandetory-number-footer'>
            {t('views.nori.step7.partition')}<div className='drop-sec'>
              <select value={lastValue} onChange={(e) => { selectApplyPartiNum(e.target.value) }}>
                {optionList}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

SeventhComponent.propTypes = {
  steps: PropTypes.array,
  setSteps: PropTypes.func,
  setPrevActiveStep: PropTypes.func
}

export default SeventhComponent
