import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import closeicon from '../../assets/images/close-icon.svg'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import '../../assets/sass/views/stacking.scss'

const ClaimModal = (props) => {
  const { t } = useTranslation('global')
  return (
    <>
      <Modal isOpen={props.isOpen} closeModal={props.closeModal} className='settings-popup result-popup' id='settings-popup'>
        <ModalBody>
          <div className='common-modal-sec'>
            <button onClick={props.closeModal} className='common-close-btn'><img src={closeicon} /></button>
            <h4 className='common-title'>{t('views.affiliates.importantUpdate')}</h4>
            <div className='result-popup-header'>
              <div className='result-popup-header'>
                <h5>{t('views.affiliates.claimModal.claimSoonAvailable')}</h5>
              </div>
            </div>
            <button onClick={props.closeModal} className='common-blue-btn small-btn'>{t('global.ok')}</button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}
ClaimModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
}

export default ClaimModal
