import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { AiOutlineEye, AiOutlineEyeInvisible, AiFillStar } from 'react-icons/ai'
import { useMutation, useQuery } from 'react-query'
import {
  TabContent, TabPane, Nav,
  NavItem, NavLink
} from 'reactstrap'
import { checkAffiliateData, saveCode, getAffiliateAutoGenerate, getReferrals, getActivities, getRewards, getMyGameTickets, getTicketDetail, getCompTickets, getResTickets, deleteReferrals, deleteActivities, deleteRewards, getClaimAllResp } from '../../query/walletconnect/walletconnect.query'
// import { getAffiliatesData } from '../../query/affiliates/affiliates.query'
import Header from '../../components/Header'
import Footer from '../../components/footer'
import Sidebar from '../../components/Sidebar'
// import Loading from '../../components/Loader'
import blueballimg from '../../assets/images/blue-ball-img.png'
import userIcon from '../../assets/images/user-icon.png'
import copyIcon from '../../assets/images/copy-icon.svg'
import DataTable from 'react-data-table-component'
import classnames from 'classnames'
import redballimg from '../../assets/images/red-ball-img.png'
import bnb from '../../assets/images/bnb.svg'
import '../../assets/sass/views/affiliates.scss'
import cookies, { getUserToken } from '../../cookies'
import { useTranslation } from 'react-i18next'

import { useDisconnect, useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/react'
import ClaimModal from './ClaimModal'

const Affiliates = () => {
  const { t } = useTranslation('global')
  const { open: openWallet } = useWeb3Modal()
  const navigate = useNavigate()
  const { state } = useLocation()
  const [loading, setLoading] = useState({ status: true })
  // affiliates tables
  const [referralsData, setReferralsData] = useState([])
  const [activitiesData, setActivitiesData] = useState([])
  const [rewardsData, setRewardsData] = useState([])
  const [mgTicketData, setMgTicketData] = useState([])
  const [compTicketData, setCompTicketData] = useState([])
  const [resTicketData, setResTicketData] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [referralsSelectedRows, setReferralsSelectedRows] = useState([])
  const [activitiesSelectedRows, setActivitiesSelectedRows] = useState([])
  const [rewardsSelectedRows, setRewardsSelectedRows] = useState([])
  const [currentActiveTab, setCurrentActiveTab] = useState(state !== null && state?.tab !== '1' ? state?.tab : '1')
  const [generatedCode, setGeneratedCode] = useState('')
  const [usersWallet, setUserSwallet] = useState(t('views.Affiliates.identity'))
  const [hasAffiliatesCode, setHasAffiliatesCode] = useState(false)
  // const [hasCode, setHasCode] = useState(true)
  const [popUpModal, setPopUpModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  const [valideBottom, setValideBottom] = useState(false)
  const [notification, setNotification] = useState('')
  const [showPopup, setShowPopup] = useState(false)
  const connectDisconnectWallet = async () => {
    resetValues()
    if (isConnected) {
      cleanTables()
      await disconnectWallet()
    } else {
      await openWallet()
    }
  }
  const [isAmountVisible, setIsAmountVisible] = useState(false)
  const toggleAmountVisibility = () => {
    setIsAmountVisible(!isAmountVisible)
  }
  /* loading start */
  const [isLoading, setIsLoading] = useState(true)
  const [dataLoaded, setDataLoaded] = useState(true)
  useEffect(() => {
    const loadData = async () => {
      setDataLoaded(false)
    }
    loadData()
  }, [])
  useEffect(() => {
    if (dataLoaded) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
    // console.log('isLoading: ', isLoading)
    // console.log('dataLoaded: ', dataLoaded)
  }, [dataLoaded])
  // listining  to isSigned
  const [isSigned, setIsSigned] = useState(() => {
    const savedValue = localStorage.getItem('confirmissigned')
    console.log('savedValue: ', savedValue)
    return savedValue !== null ? JSON.parse(savedValue) : false
  })
  const cleanTables = () => {
    setReferralsData([])
  }
  const loadVariableFromLocalStorage = () => {
    const value = localStorage.getItem('confirmissigned')
    setIsSigned(value === 'true')
  }
  useEffect(() => {
    loadVariableFromLocalStorage()
    const onStorageChange = (event) => {
      if (event.key === 'confirmissigned') {
        setIsSigned(event.newValue === 'true')
      }
    }
    window.addEventListener('storage', onStorageChange)
    return () => {
      window.removeEventListener('storage', onStorageChange)
    }
  }, [])
  const handleLocalStorageChange = (newValue) => {
    setIsSigned(newValue)
  }

  const toggle = tab => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab)
    setLoading({ ...loading, status: true })
    setTimeout(() => {
      setLoading({ ...loading, status: false })
    }, 500)
  }
  const popUpToggle = () => {
    return setPopUpModal(!popUpModal)
  }
  const deleteToggle = () => {
    return setDeleteModal(!deleteModal)
  }

  const handleReferralsChange = (referralsSelectedRows) => {
    setReferralsSelectedRows(referralsSelectedRows)
    console.log('selected Referrals Rows: ', referralsSelectedRows?.selectedRows)
  }
  const handleActivitiesChange = (activitiesSelectedRows) => {
    setActivitiesSelectedRows(activitiesSelectedRows)
  }
  const handleRewardsChange = (rewardsSelectedRows) => {
    setRewardsSelectedRows(rewardsSelectedRows)
  }
  const handleClearRows = (no, flag) => {
    popUpToggle()
    if (flag) {
      if (no === 1) {
        if (currentActiveTab === '1') deleteReferralsRefetch()
        if (currentActiveTab === '2') deleteActivitiesRefetch()
        if (currentActiveTab === '3') deleteRewardsRefetch()
      } else if (no === 2) {
        claimAllRefetch()
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading({ ...loading, status: false })
    }, 500)
  }, [])

  useQuery(['get-affiliates-details', currentActiveTab], () => getMyGameTickets(currentActiveTab), {
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      setMgTicketData([])
      /* setMgTicketData(data)
      setMgTicketData([{
        goID: 1,
        fdt: '2023-10-01T12:00:00Z',
        ldt: 12.34,
        usg: [5, 10, 15, 20, 25]
      },
      {
        goID: 2,
        fdt: '2023-10-02T12:00:00Z',
        ldt: 23.45,
        usg: [10, 15, 20, 25, 30]
      },
      {
        goID: 3,
        fdt: null,
        ldt: null,
        usg: null
      }]) */
    },
    onError: (error) => {
      if (error.request.status === 401) console.log('true')
      else navigate('/error', { state: { errorMsg: t('views.affiliates.error') } })
    }
  })

  useQuery(['get-details'], () => getTicketDetail(), {
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.affiliates.error') } })
    }
  })

  const { refetch: deleteReferralsRefetch } = useQuery('delete-refetch', () => deleteReferrals(referralsSelectedRows.selectedRows), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      const finalData = mgTicketData?.length ? [...mgTicketData] : []
      const filterData = finalData.filter((d) => !data.includes(d.goID))
      setMgTicketData(filterData)
      deleteToggle()
    }
  })

  const { refetch: refetchMyReferrals } = useQuery('get-my-referrals-details', () => getReferrals(), {
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      setReferralsData(data)
      console.log('referrals: ', data)
    },
    onError: (error) => {
      if (error.request.status === 401) console.log(error)
      else navigate('/error', { state: { errorMsg: t('views.myGame.myGame.error') } })
    }
  })

  const { refetch: refetchMyActivities } = useQuery('get-my-activities-details', () => getActivities(), {
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      setActivitiesData(data)
      console.log('activities: ', data)
    },
    onError: (error) => {
      if (error.request.status === 401) console.log(error)
      else navigate('/error', { state: { errorMsg: t('views.myGame.myGame.error') } })
    }
  })

  const { refetch: refetchMyRewards } = useQuery('get-my-rewards-details', () => getRewards(), {
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      setRewardsData(data)
      console.log('Rewards: ', data)
    },
    onError: (error) => {
      if (error.request.status === 401) console.log(error)
      else navigate('/error', { state: { errorMsg: t('views.myGame.myGame.error') } })
    }
  })

  useQuery(['get-comp', currentActiveTab], () => getCompTickets(currentActiveTab), {
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      setCompTicketData([])
      /* setCompTicketData(data)
      setCompTicketData([{
        norID: 1,
        ddt: '2023-10-01T12:00:00Z',
        nsc: 12.34,
        nws: [5, 10, 15, 20, 25]
      },
      {
        norID: 2,
        ddt: '2023-10-02T12:00:00Z',
        nsc: 23.45,
        nws: [10, 15, 20, 25, 30]
      },
      {
        norID: 3,
        ddt: null,
        nsc: null,
        nws: null
      }]) */
      // console.log('compticketdata: ', data)
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.affiliates.error') } })
    }
  })

  const { refetch: deleteActivitiesRefetch } = useQuery('delete-comp-refetch', () => deleteActivities(activitiesSelectedRows.selectedRows), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      const finalData = compTicketData?.length ? [...compTicketData] : []
      const filterData = finalData.filter((d) => !data.includes(d.norID))
      setCompTicketData(filterData)
      // console.log(filterData)
      deleteToggle()
    }
  })

  const { refetch: deleteRewardsRefetch } = useQuery('delete-refetch', () => deleteRewards(rewardsSelectedRows.selectedRows), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      const finalData = mgTicketData?.length ? [...mgTicketData] : []
      const filterData = finalData.filter((d) => !data.includes(d.goID))
      setMgTicketData(filterData)
      deleteToggle()
    }
  })

  useQuery(['get-result', currentActiveTab], () => getResTickets(currentActiveTab), {
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      setResTicketData(data)
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.affiliates.error') } })
    }
  })

  /* const { refetch: deleteResRefetch } = useQuery('delete-res-refetch', () => deleteResResp(), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      const finalData = resTicketData?.length ? [...resTicketData] : []
      const filterData = finalData.filter((d) => !data.includes(d.rid))
      setResTicketData(filterData)
      deleteToggle()
    }
  }) */

  const { refetch: claimAllRefetch } = useQuery('claim-all-refetch', () => getClaimAllResp(), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      const finalData = resTicketData?.length ? [...resTicketData] : []
      const filterData = finalData.map((d) => {
        if (data.includes(d.rid)) d.clm = true
        return d
      })
      setResTicketData(filterData)
      deleteToggle()
    }
  })
  const rows = referralsData?.map((mg) => {
    const mgArr = {}
    mgArr.id = mg.rfi
    mgArr.usr = mg?.usr
    mgArr.rdt = mg?.rdt !== null ? `${new Date(mg.rdt).getUTCDate() < 10 ? '0' + new Date(mg.rdt).getUTCDate() : new Date(mg.rdt).getUTCDate()}/${(new Date(mg.rdt).getMonth() + 1) < 10 ? '0' + (new Date(mg.rdt).getMonth() + 1) : new Date(mg.rdt).getMonth() + 1}/${new Date(mg.rdt).getFullYear()}` : '-'
    mgArr.lpd = mg?.lpd !== null ? `${new Date(mg.lpd).getUTCDate() < 10 ? '0' + new Date(mg.lpd).getUTCDate() : new Date(mg.lpd).getUTCDate()}/${(new Date(mg.lpd).getMonth() + 1) < 10 ? '0' + (new Date(mg.lpd).getMonth() + 1) : new Date(mg.lpd).getMonth() + 1}/${new Date(mg.lpd).getFullYear()}` : '-'
    return mgArr
  })
  const activitiesRows = activitiesData?.map((mg) => {
    const mgArr = {}
    mgArr.id = mg.aci
    mgArr.pdt = mg?.pdt !== null ? `${new Date(mg.pdt).getUTCDate() < 10 ? '0' + new Date(mg.pdt).getUTCDate() : new Date(mg.pdt).getUTCDate()}/${(new Date(mg.pdt).getMonth() + 1) < 10 ? '0' + (new Date(mg.pdt).getMonth() + 1) : new Date(mg.pdt).getMonth() + 1}/${new Date(mg.pdt).getFullYear()}` : '-'
    mgArr.usr = mg?.usr
    mgArr.amp = mg?.amp
    return mgArr
  })
  const rewardsRows = rewardsData?.map((mg) => {
    const mgArr = {}
    mgArr.id = mg.rwi
    mgArr.pdt = mg?.pdt !== null ? `${new Date(mg.pdt).getUTCDate() < 10 ? '0' + new Date(mg.pdt).getUTCDate() : new Date(mg.pdt).getUTCDate()}/${(new Date(mg.pdt).getMonth() + 1) < 10 ? '0' + (new Date(mg.pdt).getMonth() + 1) : new Date(mg.pdt).getMonth() + 1}/${new Date(mg.pdt).getFullYear()}` : '-'
    mgArr.rpa = mg?.rpa
    mgArr.rwd = mg?.rwd
    mgArr.rst = mg?.rwd === 0 ? '-' : mg?.rst ? t('views.myGame.myGame.claimed') : t('views.myGame.myGame.notClaimed')
    // if (rwd == 0) { "-" } elese if (rst == true) { "CLAIMED" } else { "NOT CLAIMED" }
    return mgArr
  })
  const referralsColumns = [
    {
      id: 1,
      name: t('views.affiliates.username'),
      selector: (row) => row.usr,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 2,
      name: t('views.affiliates.registerDate'),
      selector: (row) => row.rdt,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 3,
      name: t('views.affiliates.lastPlayingDate'),
      selector: (row) => row.lpd,
      sortable: false,
      reorder: false,
      center: true
    }
  ]
  const activitiesColumns = [
    {
      id: 1,
      name: t('views.affiliates.playingDate'),
      selector: (row) => row.pdt,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 2,
      name: t('views.affiliates.username'),
      selector: (row) => row.usr,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 3,
      name: <>{t('views.affiliates.playedAmount')}  { /* <a> <span> </span><img src={infoicon} /></a> */ } </>,
      selector: (row) => row.amp,
      sortable: false,
      reorder: false,
      center: true
    }
  ]
  const rewardsColumns = [
    {
      id: 1,
      name: t('views.affiliates.playingDate'),
      selector: (row) => row.pdt,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 2,
      name: t('views.affiliates.RewardsPlayedAmount'),
      selector: (row) => row.rpa,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 3,
      name: <>{t('views.affiliates.status')}  { /* <a> <span> </span><img src={infoicon} /></a> */ } </>,
      selector: (row) => row.rst,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 4,
      name: <>{t('views.affiliates.earnings')}  { /* <a> <span> </span><img src={infoicon} /></a> */ } </>,
      selector: (row) => row.rwd,
      sortable: false,
      reorder: false,
      center: true
    }
  ]

  const rowDisabledCriteria = (row) => {
    return row.rst === t('views.myGame.myGame.claimed')
  }

  const handleClaimAll = async () => {
    openModal()
    console.log('openModal: ', openModal)
    /* try {
      if (amounts.length === 0) {
        console.log('Please select the records to claim.')
        notify('error', 'Please select the records to claim.')
        return
      }
      if (!isConnected) {
        await connectWallet()
        return
      }
      setIsDisabledClaim(true)
      getSign({
        address,
        aAmounts: amounts,
        aPoolIds: poolIds
      })
    } catch (error) {
      console.log('handleClaimAll -> error', error)
      setIsDisabledClaim(false)
      notify('error', error?.message)
    } */
  }
  // console.log('NoriRows: ', noriRows)
  // console.log('Rows: ', rows)
  /* const activitiesColumns = [
    {
      id: 1,
      name: t('views.affiliates.identity'),
      selector: (row) => row.dd,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 2,
      name: t('views.affiliates.nextDrawDate'),
      selector: (row) => row.score,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 3,
      name: t('views.affiliates.payed'),
      selector: (row) => row.weight,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 4,
      name: t('views.affiliates.toPay'),
      selector: (row) => row.details,
      sortable: false,
      reorder: false,
      center: true,
      width: '150px'
    }
  ]
  */
  const paginationComponentOptions = {
    noRowsPerPage: true
  }
  const fetchData = async (page, no) => {
    const data = no === 1 ? [...mgTicketData] : no === 2 ? [...compTicketData] : [...resTicketData]
    const result = []
    for (let i = (9 * (page - 1)); i <= (page * 9) - 1; i++) {
      result.push(data[i])
    }
  }
  const handlePageChange = (page, no) => {
    fetchData(page, no)
  }

  const { disconnect } = useDisconnect()

  const disconnectWallet = async () => {
    await disconnect()
    resetValues()
    cookies.remove('sWalletAddress', { path: '/' })
    cookies.remove('userToken', { path: '/' })
  }

  // Get Address
  const { isConnected } = useWeb3ModalAccount()

  const [popupStyle, setPopupStyle] = useState({ display: 'none' })
  const handleCopy = async (event) => {
    const textToCopy = `https://www.nora642.com?code=${generatedCode}` // Récupère le texte du span cliqué
    try {
      await navigator.clipboard.writeText(textToCopy) // Copie le texte dans le presse-papiers
      const { clientX, clientY } = event
      setPopupStyle({
        display: 'block',
        left: `${clientX}px`,
        top: `${clientY}px`
      })
      setShowPopup(true)
      setNotification(t('views.affiliates.copie')) // Affiche la notification
      setTimeout(() => {
        setNotification('') // Cache la notification après 2 secondes
        setShowPopup(false)
      }, 2000) // Indiquer que le texte a été copié
    } catch (err) {
      console.error(t('views.affiliates.noCopie'), err)
    }
  }
  const [totalAmount, setTotalAmount] = useState(0)
  const [inputValue, setInputValue] = useState('')
  const { mutate: validateCodeMutation } = useMutation({
    mutationFn: (data) => {
      return saveCode(data)
    },
    onSuccess: (data) => {
      // console.log('Code saved successfully', data)
      setGeneratedCode(inputValue)
      setHasAffiliatesCode(true)
    },
    onError: (error) => {
      console.error('Error saving Code:', error)
      setHasAffiliatesCode(false)
    }
  })
  const handleValidate = () => {
    setDataLoaded(true)
    validateCodeMutation({ cd: inputValue })
    // alert(`Validé : ${inputValue}`)
  }
  const resetValues = () => {
    setHasAffiliatesCode(false)
    setValideBottom(false)
    setShowPopup(false)
    setGeneratedCode('')
    setUserSwallet('')
    setNotification('')
    setTotalAmount(0)
    setIsSigned(false)
    localStorage.setItem('confirmissigned', JSON.stringify(false))
  }
  const { mutate: checkAffliate } = useMutation({
    mutationFn: checkAffiliateData,
    onSuccess: (data) => {
      // console.log('data.data: ', data.data)
      if (data?.status === 204) {
        setHasAffiliatesCode(false)
        // setHasCode(false)
        // console.log('No affiliate data ')
        // console.log('hasCode Value: ', hasCode)
        // console.log(data)
      } else if (data.data?.usr && !data.data?.cd) {
        setUserSwallet(data.data?.usr)
      } else {
        // console.log(data)
        // const cd = data ? data.data?.cd : ''
        // const usr = data ? data.data?.usr : ''
        setGeneratedCode(data.data?.cd)
        setUserSwallet(data.data?.usr)
        setTotalAmount(data.data?.trw)
        console.log(data.data)
        // console.log('generatedCode11: ' + generatedCode + cd)
        // console.log('userSwallet11: ' + usersWallet + usr)
        setHasAffiliatesCode(true)
        // setHasCode(true)
      }
      setDataLoaded(false)
    },
    onError: (error) => {
      // navigate('/error', { state: { errorMsg: 'Internal Server Error' } })
      if (error.request.status === 401) {
        console.log('401: non connecté')
      } else {
        console.log('autre code: erreur inconnue: ', error.request.status)
        setShowPopup(true)
        setNotification(t('views.affiliates.error')) // Affiche la notification
        setTimeout(() => {
          setNotification('') // Cache la notification après 2 secondes
          setShowPopup(false)
        }, 2000)
      }
      setHasAffiliatesCode(false)
      setDataLoaded(false)
    }
  })
  const { mutate: getAutoGe } = useMutation({
    mutationFn: getAffiliateAutoGenerate,
    onSuccess: (data) => {
      // return data
      // console.log(data)
      const cd = data ? data.data?.cd : ''
      // const usr = data ? data.data?.usr : ''
      setGeneratedCode(cd)
      // setUserSwallet(usr)
      // console.log('generatedCode: ' + generatedCode)
      setShowPopup(true)
      setNotification(t('views.affiliates.codeGenereted')) // Affiche la notification
      setTimeout(() => {
        setNotification('') // Cache la notification après 2 secondes
        setShowPopup(false)
      }, 2000)
      setDataLoaded(false)
    },
    onError: (error) => {
      // navigate('/error', { state: { errorMsg: 'Internal Server Error' } })
      if (error.request.status === 401) console.log('401: non connecté')
      else if (error.request.status === 204) {
        console.log('204: gerenation erreur')
        setShowPopup(true)
        setNotification(t('views.affiliates.generationError')) // Affiche la notification
        setTimeout(() => {
          setNotification('') // Cache la notification après 2 secondes
          setShowPopup(false)
        }, 2000)
      } else {
        console.log('autre code: erreur inconnue')
        setShowPopup(true)
        setNotification(t('views.affiliates.error')) // Affiche la notification
        setTimeout(() => {
          setNotification('') // Cache la notification après 2 secondes
          setShowPopup(false)
        }, 2000)
      }
      setDataLoaded(false)
    }
  })
  const handleAutoGenerate = async () => {
    setDataLoaded(true)
    getAutoGe()
    // const generatedValue = Math.random().toString(36).substring(4, 8)
    // const generatedValue = generatedCode
    // console.log('inputValue.length ' + inputValue?.length)
    setInputValue(generatedCode)
    setValideBottom(true)
  }
  const loadin = () => {
    setDataLoaded(true)
  }
  const stopLoading = () => {
    setDataLoaded(false)
  }
  useEffect(() => {
    setInputValue(generatedCode)
  }, [generatedCode])
  useEffect(() => {
    refetchMyReferrals()
    refetchMyActivities()
    refetchMyRewards()
  }, [])
  useEffect(() => {
    // console.log('UseEffect hasCode: ', hasCode)
    if ((isSigned)) {
      loadin()
      const fetchData = async () => {
        try {
          checkAffliate()
          const userToken = getUserToken()
          if (userToken) {
            refetchMyReferrals()
            refetchMyActivities()
            refetchMyRewards()
            stopLoading()
          }
          // console.log('generatedCode1: ' + generatedCode)
          // console.log('UsersWallet2: ' + usersWallet)
        } catch (error) {
          console.error('Error fetching ticket price:', error)
        }
      }
      const intervalId = setInterval(() => {
        if (!hasAffiliatesCode) {
          const userToken = getUserToken()
          // console.log('hasCode: ', hasCode)
          // console.log('hasAffiliatesCode: ', hasAffiliatesCode)
          // console.log('getUserToken: ', userToken)
          if (userToken) {
            // console.log('UserToken is not null')
            fetchData()
            stopLoading()
          }
        } else {
          stopLoading()
          clearInterval(intervalId) // Arrêter l'intervalle si shouldFetch devient true
        }
      }, 500) // Exécute toutes les 5 secondes
      return () => clearInterval(intervalId)
    } else {
      setDataLoaded(false)
      resetValues()
    }
    console.log('change IsSigned: ', isSigned, ', isConnected: ', isConnected)
  }, [isConnected, hasAffiliatesCode, isSigned])
  const isValid = inputValue?.length >= 4 && inputValue?.length <= 10

  const [isModalOpen, setIsModalOpen] = useState(false)
  const openModal = () => {
    setIsModalOpen(true)
  }
  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
      <>
        { /* loading.status ? <Loading addClass={'active'} /> : '' */ }
        {isLoading}
        <><Header activeTab={5} onLocalStorageChange={handleLocalStorageChange} />
          <div className='statics-tabs-sec my-game-sec'>
            <Sidebar/>
            {/* <Sidebar sideActiveTab={4}/> */}
            {!(isConnected && isSigned)
              ? <div className='statics-main-sec-holder'>
                <div className="notConnectedBG">
                  <img className='red' src={redballimg} alt="Ball" />
                  <img className='blue' src={blueballimg} alt="Ball" />
                  <div>
                    <div className='message'>
                      <h3>{t('global.infoConnect')}</h3>
                      <button className='connect' onClick={() => connectDisconnectWallet()}> <img src={bnb} alt='BNB' /> {t('connectWallet')}</button>
                    </div>
                  </div>
                </div>
              </div>
              : !hasAffiliatesCode
                  ? <div className='statics-main-sec my-games-main-sec'>
                    <img src={blueballimg} className='blueballimg' />
                    <div className="container">
                      <h3 className='not-generated'>{t('views.affiliates.NoCodeGenerated')}</h3><br/>
                      <div className="user-info row">
                        <div className="username col-12 col-md-6"><img src={userIcon} className='user-icon' />{usersWallet}</div>
                        <div className='input-field col-12 col-md-6 row'>
                          <label className='col-7 col-md-6' htmlFor="inputField">{t('views.affiliates.enterCode')} : </label>
                          <input
                              className='col-7 col-md-6'
                              type="text"
                              id="inputField"
                              value={inputValue}
                              onChange={(e) => { setInputValue(e.target.value.slice(0, 10)); setValideBottom(false) }} // Limite à 8 caractères
                          />
                        </div>
                      </div>
                      <div className='selection'>
                        <button className='ok-button' onClick={handleValidate} disabled={!isValid && !valideBottom}>{t('views.affiliates.validate')}</button>
                        <button className='ok-button' onClick={handleAutoGenerate}>{t('views.affiliates.autogenerate')}</button>
                      </div>
                      <p className='warning'>{isValid || valideBottom ? '' : t('views.affiliates.enterCaraters')}</p>
                    </div>
                  </div>
                  : <div className='statics-main-sec my-games-main-sec'>
                      <img src={blueballimg} className='blueballimg' />
                      <div className='container'>
                        <div className='user-info row'>
                          <div className='username col-12 col-md-6'>
                            <div><img src={userIcon} className='user-icon' />{usersWallet}</div>
                            <div className='total-amount'>
                            {t('views.affiliates.totalAmount')} {!isAmountVisible ? <><AiFillStar className='star-icon'/><AiFillStar className='star-icon'/><AiFillStar className='star-icon'/><AiFillStar className='star-icon'/></> : totalAmount} BNB
                              <button onClick={toggleAmountVisibility} style={{ fontSize: '24px', cursor: 'pointer' }}>
                                {isAmountVisible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                              </button>
                            </div>
                          </div>
                          <div className='usercode col-12 col-md-6'>
                            <h3 className='selection'>{t('views.affiliates.code')}: {generatedCode}</h3>
                            <div className='selection'>
                              <p className='d-link'>{t('views.affiliates.myLink')} : <span onClick={handleCopy} className='copy-text'>https://www.nora642.com?code={generatedCode}<img src={copyIcon} className='copy-icon' alt='copier'/></span></p>
                              { /* <p className='copie-link'>copier</p> */ }
                              {showPopup && (
                                  <div className="popup-message" style={popupStyle}>
                                    {notification}
                                  </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='text-center'>
                        <h1>{t('views.affiliates.affiliation')}</h1>
                      </div>
                      <div className='stacking-tabs'>
                        <Nav tabs>
                          <NavItem>
                            <NavLink
                                className={classnames({
                                  active:
                                      currentActiveTab === '1'
                                })}
                                onClick={() => { toggle('1'); refetchMyReferrals() }}
                            >
                              {t('views.affiliates.myReferrals')}
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                                className={classnames({
                                  active:
                                      currentActiveTab === '2'
                                })}
                                onClick={() => { toggle('2'); refetchMyActivities() }}
                            >
                              {t('views.affiliates.activities')}
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active:
                                  currentActiveTab === '3'
                              })}
                              onClick={() => { toggle('3'); refetchMyRewards() }}
                            >
                              {t('views.affiliates.rewards')}
                            </NavLink>
                          </NavItem>

                        </Nav>
                      </div>
                      <TabContent activeTab={currentActiveTab}>
                        <TabPane tabId="1">
                          <div className='p-relative'>
                            <img src={redballimg} className='redballimg' />
                            <div className='statics-sec my-games-sec'>
                              <div className='statics-header mb-30 mygames-header'>
                                <h4 className='common-title'>{t('views.affiliates.myReferrals')}</h4>
                                {referralsSelectedRows?.selectedCount > 0
                                  ? <div>
                                    <a className='common-white-btn small-btn delete-ticket-btn mob-delet-btn' onClick={() => handleClearRows(1, false)}><span>{t('views.affiliates.delete')}</span></a>
                                  </div>
                                  : ''
                                }
                              </div>
                              <div className='common-table-sec my-games-table'>
                                <DataTable
                                    columns={referralsColumns}
                                    data={rows}
                                    pagination
                                    paginationPerPage={9}
                                    paginationComponentOptions={paginationComponentOptions}
                                    onChangeRowsPerPage={() => handlePageChange(1) }
                                    selectableRows
                                    onSelectedRowsChange={(e) => handleReferralsChange(e)}
                                />
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="2">
                        <div className='p-relative'>
                          <img src={redballimg} className='redballimg' />
                          <div className='statics-sec my-games-sec'>
                            <div className='statics-header mb-30 mygames-header'>
                              <h4 className='common-title'>{t('views.affiliates.myActivities')}</h4>
                              {activitiesSelectedRows?.selectedCount > 0
                                ? <div>
                                  <a className='common-white-btn small-btn delete-ticket-btn mob-delet-btn' onClick={() => handleClearRows(2, false)}><span>{t('views.affiliates.delete')}</span></a>
                                </div>
                                : ''
                                }
                              </div>
                              <div className='common-table-sec my-games-table'>
                                <DataTable
                                    columns={activitiesColumns}
                                    data={activitiesRows}
                                    pagination
                                    paginationPerPage={9}
                                    paginationComponentOptions={paginationComponentOptions}
                                    onChangeRowsPerPage={() => handlePageChange(2) }
                                    selectableRows
                                    onSelectedRowsChange={(e) => handleActivitiesChange(e)}
                                />
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="3">
                        <div className='p-relative'>
                          <img src={redballimg} className='redballimg' />
                          <div className='statics-sec my-games-sec'>
                            <div className='statics-header mb-30 mygames-header'>
                              <h4 className='common-title'>{t('views.affiliates.myRewards')}</h4>
                              {rewardsSelectedRows?.selectedCount > 0
                                ? <div>
                                  <a className='common-white-btn small-btn delete-ticket-btn mob-delet-btn' onClick={() => handleClearRows(3, false)}><span>{t('views.affiliates.delete')}</span></a>
                                </div>
                                : ''
                                }
                                <button className='common-blue-btn small-btn' onClick={() => handleClaimAll()} /* disabled={isDisabledClaim} */ ><span>{t('views.myGame.myGame.claimAll')}</span></button>
                                </div>
                                <ClaimModal isOpen={isModalOpen} closeModal={closeModal} />
                              <div className='common-table-sec my-games-table'>
                                <DataTable
                                    columns={rewardsColumns}
                                    data={rewardsRows}
                                    pagination
                                    paginationPerPage={9}
                                    paginationComponentOptions={paginationComponentOptions}
                                    onChangeRowsPerPage={() => handlePageChange(3) }
                                    selectableRows
                                    onSelectedRowsChange={(e) => handleRewardsChange(e)}
                                    selectableRowDisabled={rowDisabledCriteria}
                                />
                              </div>
                            </div>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>}
          </div>
          <Footer isVisibleBall={false} />
        </></>
  )
}

export default Affiliates
