import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import '../assets/sass/views/Nav.scss'
import { FaCaretDown } from 'react-icons/fa'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap'
import logo from '../assets/images/logo.svg'
import bnb from './../assets/images/bnb.svg'
import logochainlinkblue from './../assets/images/logochainlinkblue.svg'
import chainlinksmalllogo from './../assets/images/chainlinksmalllogo.svg'
import Modal from './Modal'
import enFlag from '../assets/images/flags/enFlag.svg'
import deFlag from '../assets/images/flags/deFlag.svg'
import frFlag from '../assets/images/flags/frFlag.svg'
import esFlag from '../assets/images/flags/esFlag.svg'
import itFlag from '../assets/images/flags/itFlag.svg'
import ptFlag from '../assets/images/flags/ptFlag.svg'
import nlFlag from '../assets/images/flags/nlFlag.svg'
import tkFlag from '../assets/images/flags/tkFlag.svg'
import ruFlag from '../assets/images/flags/ruFlag.svg'
import roFlag from '../assets/images/flags/roFlag.svg'
import zhFlag from '../assets/images/flags/zhFlag.svg'
import koFlag from '../assets/images/flags/koFlag.svg'
import jpFlag from '../assets/images/flags/jpFlag.svg'
import plFlag from '../assets/images/flags/plFlag.svg'

import { useTranslation } from 'react-i18next'
import { useDisconnect, useSwitchNetwork, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react'
import { useMutation } from 'react-query'
import { getNonce, signInWithEthereum, verifySignature } from '../query/walletconnect/walletconnect.query'
import { BrowserProvider } from 'ethers'
import cookies from '../cookies'
import { notify } from '../utils/notify'

const Header = (props) => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate('/', { state: { ticketState: props.tickets, currentPoolData: props.currentPoolData, ncbTickets: props.ncbTickets, stepsTickets: props.stepsTickets } })
  }
  const [isOpen, setIsOpen] = React.useState(false)
  const [show, setShow] = useState(false)
  const [previousAddress, setPreviousAddress] = useState(null)

  const { t, i18n } = useTranslation('global')
  const curentLanguage = sessionStorage.getItem('curentLanguage') ? sessionStorage.getItem('curentLanguage') : navigator.language.split('-')[0] === 'zh' || navigator.language.split('-')[0] === 'de' || navigator.language.split('-')[0] === 'es' || navigator.language.split('-')[0] === 'fr' || navigator.language.split('-')[0] === 'it' || navigator.language.split('-')[0] === 'ko' || navigator.language.split('-')[0] === 'nl' || navigator.language.split('-')[0] === 'pt' || navigator.language.split('-')[0] === 'ro' || navigator.language.split('-')[0] === 'ru' || navigator.language.split('-')[0] === 'tk' || navigator.language.split('-')[0] === 'jp' || navigator.language.split('-')[0] === 'pl' ? navigator.language.split('-')[0] : 'en'
  const [ln, setLn] = useState(<div><img src={curentLanguage === 'zh' ? zhFlag : curentLanguage === 'de' ? deFlag : curentLanguage === 'es' ? esFlag : curentLanguage === 'fr' ? frFlag : curentLanguage === 'it' ? itFlag : curentLanguage === 'ko' ? koFlag : curentLanguage === 'nl' ? nlFlag : curentLanguage === 'pt' ? ptFlag : curentLanguage === 'ro' ? roFlag : curentLanguage === 'ru' ? ruFlag : curentLanguage === 'tk' ? tkFlag : curentLanguage === 'jp' ? jpFlag : curentLanguage === 'pl' ? plFlag : enFlag} alt={curentLanguage} /><strong>{curentLanguage.toUpperCase()}</strong><FaCaretDown /></div>)
  // console.log('curentLanguage: ', curentLanguage)
  useEffect(() => {
    sessionStorage.setItem('curentLanguage', curentLanguage)
    i18n.changeLanguage(curentLanguage)
  }, [])
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => {
    setOpen(!open)
  }
  const handleChangeLang = (lang, e) => {
    sessionStorage.setItem('curentLanguage', lang)
    i18n.changeLanguage(lang)

    setOpen(false)
    if (lang === 'en') {
      setLn(<div>
        <img src={enFlag} alt='EN' />
        <strong>EN</strong>
        <FaCaretDown />
      </div>)
    } else if (lang === 'de') {
      setLn(<div>
        <img src={deFlag} alt='DE' />
        <strong>DE</strong>
        <FaCaretDown />
      </div>)
    } else if (lang === 'fr') {
      setLn(<div>
        <img src={frFlag} alt='FR' />
        <strong>FR</strong>
        <FaCaretDown />
      </div>)
    } else if (lang === 'es') {
      setLn(<div>
        <img src={esFlag} alt='ES' />
        <strong>ES</strong>
        <FaCaretDown />
      </div>)
    } else if (lang === 'it') {
      setLn(<div>
        <img src={itFlag} alt='IT' />
        <strong>IT</strong>
        <FaCaretDown />
      </div>)
    } else if (lang === 'pt') {
      setLn(<div>
        <img src={ptFlag} alt='PT' />
        <strong>PT</strong>
        <FaCaretDown />
      </div>)
    } else if (lang === 'nl') {
      setLn(<div>
        <img src={nlFlag} alt='NL' />
        <strong>NL</strong>
        <FaCaretDown />
      </div>)
    } else if (lang === 'tk') {
      setLn(<div>
        <img src={tkFlag} alt='TK' />
        <strong>TK</strong>
        <FaCaretDown />
      </div>)
    } else if (lang === 'ru') {
      setLn(<div>
        <img src={ruFlag} alt='RU' />
        <strong>RU</strong>
        <FaCaretDown />
      </div>)
    } else if (lang === 'ro') {
      setLn(<div>
        <img src={roFlag} alt='RO' />
        <strong>RO</strong>
        <FaCaretDown />
      </div>)
    } else if (lang === 'zh') {
      setLn(<div>
        <img src={zhFlag} alt='ZH' />
        <strong>ZH</strong>
        <FaCaretDown />
      </div>)
    } else if (lang === 'ko') {
      setLn(<div >
        <img src={koFlag} alt='KO' />
        <strong>KO</strong>
        <FaCaretDown />
      </div>)
    } else if (lang === 'pl') {
      setLn(<div >
        <img src={plFlag} alt='PL' />
        <strong>PL</strong>
        <FaCaretDown />
      </div>)
    } else if (lang === 'jp') {
      setLn(<div >
        <img src={jpFlag} alt='JP' />
        <strong>JP</strong>
        <FaCaretDown />
      </div>)
    }
    setIsOpen(false)
  }

  // Wallet connect
  const { open: openWallet } = useWeb3Modal()
  const { disconnect } = useDisconnect()
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { switchNetwork } = useSwitchNetwork()
  const { walletProvider } = useWeb3ModalProvider()
  const [isSigned, setIsSigned] = useState(() => {
    const savedValue = localStorage.getItem('confirmissigned')
    return savedValue !== null ? JSON.parse(savedValue) : false
  })
  useEffect(() => {
    localStorage.setItem('confirmissigned', JSON.stringify(isSigned))
    if (props.onLocalStorageChange) {
      props.onLocalStorageChange(isSigned)
    }
  }, [isSigned])
  const connectDisconnectWallet = async () => {
    setIsSigned(false)
    if (isConnected) {
      await disconnectWallet()
    } else {
      await openWallet()
    }
  }

  const disconnectWallet = async () => {
    setIsSigned(false)
    setPreviousAddress(null)
    cookies.remove('sWalletAddress', { path: '/' })
    cookies.remove('userToken', { path: '/' })
    await disconnect()
    notify('success', 'Wallet disconnected')
  }
  const { mutate: mutateNonce } = useMutation({
    mutationFn: () => getNonce(),
    retry: true,
    onSuccess: (data) => {
      signAndVerifySignature(data.data)
    },
    onError: () => {
      disconnectWallet()
    }
  })

  const { mutate: mutateVerifySignature } = useMutation({
    mutationFn: (data) => verifySignature(data),
    onSuccess: (oRes) => {
      cookies.set('sWalletAddress', oRes?.data?.data?.sWalletAddress, { path: '/', secure: true, sameSite: 'none' })
      cookies.set('userToken', `Bearer ${oRes?.data?.data?.sToken}`, { path: '/', secure: true, sameSite: 'none' })
      cookies.remove('noraCode', { path: '/' })
      notify('success', 'Wallet connected')
      setIsSigned(true)
    },
    onError: (error) => {
      notify('error', error?.response?.data?.message || error?.message)
      disconnectWallet()
    }
  })

  async function signAndVerifySignature (data) {
    try {
      if (data?.data && chainId === Number(process.env.REACT_APP_NODE_ENV === 'dev'
        ? process.env.REACT_APP_TESTNET_CHAIN_ID
        : process.env.REACT_APP_MAINNET_CHAIN_ID)) {
        const ethersProvider = new BrowserProvider(walletProvider)
        const oData = await signInWithEthereum(data?.data, ethersProvider)
        setIsSigned(true)
        if (oData?.sSignature) {
          mutateVerifySignature({
            sWalletAddress: address,
            sSignature: oData?.sSignature,
            sMessage: oData?.sMessage,
            noraCode: cookies.get('noraCode')
          })
        }
      }
    } catch (error) {
      setIsSigned(false)
      disconnectWallet()
    }
  }

  useEffect(() => {
    const nChainId =
          process.env.REACT_APP_NODE_ENV === 'dev'
            ? process.env.REACT_APP_TESTNET_CHAIN_ID
            : process.env.REACT_APP_MAINNET_CHAIN_ID

    if (chainId !== Number(nChainId)) {
      switchNetwork(Number(nChainId))
    }
    if (isConnected && chainId === Number(nChainId) && !isSigned && !cookies.get('sWalletAddress')) {
      mutateNonce()
    }
  }, [chainId])

  useEffect(() => {
    if (address && previousAddress && address !== previousAddress) {
      disconnectWallet()
      // notify('info', 'Account changed - Please reconnect your wallet')
    }
    if (isConnected) {
      setPreviousAddress(address)
    }
  }, [address, isConnected])
  return (
    <div>
      <Navbar expand="md" className={isOpen === true ? 'toggle-btn-open' : ''}>
      <NavbarBrand onClick={handleClick}><img src={logo} alt='logo' /></NavbarBrand>
      <div className='chainlink-navbar-logo'>
        <a href="https://chain.link/badge" target="_blank" rel="noreferrer">
          <img className='chainlink-logo' src={logochainlinkblue} alt='logo' />
          <img className='chainlink-smalllogo' src={chainlinksmalllogo} alt='logo' />
        </a>
      </div>
        <NavbarToggler onClick={() => { setIsOpen(!isOpen) }} >
          <span></span>
          <span></span>
          <span></span>
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem className={props.activeTab === 1 ? 'active' : ''}>
              <NavLink onClick={handleClick}>{t('component.header.play')}</NavLink>
            </NavItem>
            {/**
             <NavItem className={props.activeTab === 2 ? 'active' : ''} style={{ display: 'none' }}>
              <NavLink href="/staking-rewards">{t('component.header.staking')}</NavLink>
            </NavItem>
             */}
            <NavItem className={props.activeTab === 2 ? 'active' : ''}>
              <NavLink href="/videos">{t('component.header.videos')}</NavLink>
            </NavItem>
            <NavItem className={props.activeTab === 3 ? 'active' : ''}>
              <NavLink href="/tutorial">{t('component.header.tutorial')}</NavLink>
            </NavItem>
            <NavItem className={props.activeTab === 4 ? 'active' : ''}>
              <NavLink href="/help">{t('component.header.help')}</NavLink>
            </NavItem>
            <NavItem className={props.activeTab === 5 ? 'active' : ''}>
              <NavLink href="/affiliates">{t('views.affiliates.affiliation')}</NavLink>
            </NavItem>
            {/**
             <NavItem className={props.activeTab === 4 ? 'active' : ''} style={{ display: 'none' }}>
              <NavLink href="/">{t('component.header.help')}</NavLink>
            </NavItem>
             */}
            <NavItem className="connect-wallte-btn">
              {/* <NavLink href="#" onClick={() => setShow(true)}>{t('component.header.connect')}</NavLink> */}
              <NavLink href="#" onClick={() => { connectDisconnectWallet(); setIsOpen(false) }}><img className='bnbLogo' src={bnb} alt='BNB' />{isSigned && isConnected ? `${address.slice(0, 4)}...${address.slice(-4)}` : t('component.header.connect')}</NavLink>
              <Modal title="Important Note!" content1="The features to interact with the blockchain are coming soon. No need to open your wallet for now." validate="OK" onClose={() => setShow(false)} show={show} />
            </NavItem>
            <NavItem>
              <div className="dropdown">
                <button onClick={handleOpen}>
                  {React.cloneElement(ln)}
                </button>
                {open
                  ? <ul className="menu">
                    <li key="en" className="menu-item">
                      <button onClick={(e) => handleChangeLang('en', e)}>
                        <img src={enFlag} alt='EN' />
                        <span>English</span>
                        <strong>EN</strong>
                      </button>
                    </li>
                    <li key="fr" className="menu-item">
                      <button onClick={(e) => handleChangeLang('fr', e)}>
                        <img src={frFlag} alt='FR' />
                        <span>Français</span>
                        <strong>FR</strong>
                      </button>
                    </li>
                    <li key="de" className="menu-item">
                      <button onClick={(e) => handleChangeLang('de', e)}>
                        <img src={deFlag} alt='DE' />
                        <span>Deutsch</span>
                        <strong>DE</strong>
                      </button>
                    </li>
                    <li key="es" className="menu-item">
                      <button onClick={(e) => handleChangeLang('es', e)}>
                        <img src={esFlag} alt='ES' />
                        <span>Español</span>
                        <strong>ES</strong>
                      </button>
                    </li>
                    <li key="it" className="menu-item">
                      <button onClick={(e) => handleChangeLang('it', e)}>
                        <img src={itFlag} alt='IT' />
                        <span>Italiano</span>
                        <strong>IT</strong>
                      </button>
                    </li>
                    <li key="pt" className="menu-item">
                      <button onClick={(e) => handleChangeLang('pt', e)}>
                        <img src={ptFlag} alt='PT' />
                        <span>Português</span>
                        <strong>PT</strong>
                      </button>
                    </li>
                    <li key="nl" className="menu-item">
                      <button onClick={(e) => handleChangeLang('nl', e)}>
                        <img src={nlFlag} alt='NL' />
                        <span>Nederlands</span>
                        <strong>NL</strong>
                      </button>
                    </li>
                    <li key="tk" className="menu-item">
                      <button onClick={(e) => handleChangeLang('tk', e)}>
                        <img src={tkFlag} alt='TK' />
                        <span>Türkçe</span>
                        <strong>TK</strong>
                      </button>
                    </li>
                    <li key="ru" className="menu-item">
                      <button onClick={(e) => handleChangeLang('ru', e)}>
                        <img src={ruFlag} alt='RU' />
                        <span>русский</span>
                        <strong>RU</strong>
                      </button>
                    </li>
                    <li key="ro" className="menu-item">
                      <button onClick={(e) => handleChangeLang('ro', e)}>
                        <img src={roFlag} alt='RO' />
                        <span>Roman</span>
                        <strong>RO</strong>
                      </button>
                    </li>
                    <li key="zh" className="menu-item">
                      <button onClick={(e) => handleChangeLang('zh', e)}>
                        <img src={zhFlag} alt='ZH' />
                        <span>中国人</span>
                        <strong>ZH</strong>
                      </button>
                    </li>
                    <li key="ko" className="menu-item">
                      <button onClick={(e) => handleChangeLang('ko', e)}>
                        <img src={koFlag} alt='KO' />
                        <span>한국어</span>
                        <strong>KO</strong>
                      </button>
                    </li>
                    <li key="pl" className="menu-item">
                      <button onClick={(e) => handleChangeLang('pl', e)}>
                        <img src={plFlag} alt='PL' />
                        <span>Polish</span>
                        <strong>PL</strong>
                      </button>
                    </li>
                    <li key="jp" className="menu-item">
                      <button onClick={(e) => handleChangeLang('jp', e)}>
                        <img src={jpFlag} alt='JP' />
                        <span>日本語</span>
                        <strong>JP</strong>
                      </button>
                    </li>
                  </ul>
                  : null}
              </div>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  )
}

Header.propTypes = {
  tickets: PropTypes.array,
  activeTab: PropTypes.number,
  currentPoolData: PropTypes.object,
  ncbTickets: PropTypes.number,
  stepsTickets: PropTypes.string,
  onLocalStorageChange: PropTypes.func
}

export default Header
